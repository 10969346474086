import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios'; // For API requests
import NextButton from './NextButton';
import t from '../../../lib/i18n';
import { API_HOST_V3 } from "core/src/lib/constants";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledWrapper = styled.div`
  position: relative;
`;
const SuggestionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none; /* To avoid double borders with input */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure the list appears above other elements */
  margin: 0;
  padding: 0;
  list-style: none;
`;
const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;


export default class QuestionUSPSAddress extends Component {
  constructor(props) {
    super(props);
    this.autocompleteInputRef = React.createRef();
    this.state = {
      value: '',
      unitNumber: '',
      deliveryInstructions: '',
      isValidAddressSelected: false,
    };
  }
  
  handleAddressChange = async (e) => {
    const address = e.target.value;
    this.setState({ value: address, suggestions: [] });
    
    if (address.length > 5) { // Trigger API call after minimum address length met
      try {
        const queryString = `street=${encodeURIComponent(address)}`;
        const url = `${API_HOST_V3}/location/usps_search?${queryString}`;
        const response = await axios.get(url);
        if (response.data.suggestions && response.data.suggestions.length > 0) {
          const suggestions = response.data.suggestions;
          this.setState({ suggestions, isValidAddressSelected: false });
        } else {
          this.setState({ suggestions: [], isValidAddressSelected: false });
        }
      } catch (error) {
        console.error('API Error:', error);
        this.setState({ isValidAddressSelected: false });
      }
    } else {
      this.setState({ isValidAddressSelected: false });
    }
  };
  
  handleNext = () => {
    if (this.state.value && this.state.isValidAddressSelected) {
      const submission = {
        key: this.props.step.fields[0].question_id,
        label: {
          address: this.state.value,
          unitNumber: this.state.unitNumber,
          deliveryInstructions: this.state.deliveryInstructions
        }
      };
      this.props.onSubmit(submission);
      this.setState({ value: '', unitNumber: '', deliveryInstructions: '', isValidAddressSelected: false });
    }
  };
  
  handleSuggestionClick = (suggestion) => {
    const fullAddress = `${suggestion.street_line}, ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`;
    this.setState({
      value: fullAddress,
      suggestions: [],
      isValidAddressSelected: true,
    });
  };
  
  render() {
    const { value, suggestions } = this.state;
    return (
      <Wrap>
      <StyledWrapper>
        <input
          style={{ margin: 60, width: '100%', maxWidth: 400 }}
          ref={this.autocompleteInputRef}
          type="text"
          placeholder={t('Enter your address')}
          value={value}
          onChange={this.handleAddressChange}
        />
        {suggestions && suggestions.length > 0 && (
          <SuggestionsList>
            {suggestions.map((suggestion, index) => {
              const fullAddress = `${suggestion.street_line}, ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`;
              return (
                <SuggestionItem
                  key={`${suggestion.street_line}-${suggestion.zipcode}-${index}`}
                  onClick={() => this.handleSuggestionClick(suggestion)}
                >
                  {fullAddress}
                </SuggestionItem>
              );
            })}
          </SuggestionsList>
        )}
      </StyledWrapper>
        {this.state.value && (
          <>
            <input
              style={{ margin: 20, marginTop: 0, width: '100%', maxWidth: 400 }}
              type="text"
              placeholder={t('Unit Number')}
              value={this.state.unitNumber}
              onChange={(e) => this.setState({ unitNumber: e.target.value })}
            />
            <input
              style={{ margin: 20, width: '100%', maxWidth: 400 }}
              type="text"
              placeholder={t('Delivery Instructions')}
              value={this.state.deliveryInstructions}
              onChange={(e) => this.setState({ deliveryInstructions: e.target.value })}
            />
          </>
        )}
        <NextButton
          {...this.props}
          onClick={this.handleNext}
          disabled={!this.state.isValidAddressSelected}
          label="Next"
        />
      </Wrap>
    );
  }
}
